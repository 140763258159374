//import logo from './logo.svg';
import Card from './components/Card.js';
import NavBar from './components/Navbar.js';

function App() {
  return (
    <div>
      <NavBar />
      <Card />
    </div>
    
  );
}

export default App;
