import React from 'react';
import "../assets/card.scss";

export default function Card() {
    return (
        <div class="content">
            <div class="card">
                <div class="card__side card__side--front">
                    <div class="card__cont">
                        <span class="blue">alert</span>
                        <span>(<span class="green">'Hello World!'</span>)</span>
                    </div>
                </div>
                <div class="card__side card__side--back">
                    <div class="card__cta">
                        <p>
                            <span class="purple">const</span> aboutMe <span class="cyan">=</span> &#123;
                            <br />
                            <span class="space red">name</span>
                            <span class="cyan">:</span> <span class="green">'zach boemer'</span>,
                            <br />
                            <span class="space red">position</span>
                            <span class="cyan">:</span> <span class="green">'backend web developer'</span>,
                            <br />
                            <span class="space red">email</span>
                            <span class="cyan">:</span> <span class="green">'zacharyboemer@gmail.com'</span>,
                            <br />
                            <span class="space red">github</span>
                            <span class="cyan">:</span> <span class="green">'github.com/zachboemer'</span>
                            <br />
                            &#125;&#59;
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
